
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import ServicoTipoDocumentoFinanceiro from '@/servicos/Cadastros/Financeiro/ServicoTipoDocumentoFinanceiro';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import Card from '@/core/components/Tela/Card.vue';
import SelecionarMeioPagamento from '@/components/Cadastros/Financeiro/SelecionarMeioPagamento.vue';
import { ITipoDocumentoFinanceiro, ITipoDocumentoFinanceiroEmpresa } from '@/models/Entidades/Cadastros/Financeiro/ITipoDocumentoFinanceiro';
import BaixaTitulosRepasse from '@/components/Financeiro/BaixaTitulosFinanceiros/BaixaTitulosRepasse.vue';

export default defineComponent({
  name: 'TipoDocumentoFinanceiroModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    RequisicaoModal,
    Icone,
    Card,
    SelecionarMeioPagamento,
  },
  emits: ['sincronizarRegistro', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasComEstrategiaPermissaoDados,
      preencherPermissoesDados, defineEmpresasSelecionadasComPermissao, filtrarPermissaoDadosUsuarioMultiEmpresas, apresentarMensagemSucesso,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso, sincronizarRegistro,
    } = useModalBase(props, emit);
    const servicoTipoDocumentoFinanceiro = new ServicoTipoDocumentoFinanceiro();
    telaBase.identificadorRecurso = 'CADASTRO_TIPOS_DOCUMENTO_FINANCEIRO';
    telaBase.identificadorPermissao = 'PER_CADASTRO_TIPOS_DOCUMENTO_FINANCEIRO';

    const state = reactive({
      tipoDocumento: {} as ITipoDocumentoFinanceiro,
    });

    function objetoInicial() {
      state.tipoDocumento = {} as ITipoDocumentoFinanceiro;
      state.tipoDocumento.ativo = true;
      state.tipoDocumento.meioPagamento = 1;
      state.tipoDocumento.contasReceber = true;
      state.tipoDocumento.contasPagar = true;
      state.tipoDocumento.movimentacaoEntrada = true;
      state.tipoDocumento.movimentacaoSaida = true;
      state.tipoDocumento.incideLimiteCredito = true;
      state.tipoDocumento.validaBloqueioCliente = true;
    }

    async function salvar(salvarNovo: boolean) {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      state.tipoDocumento.empresas = [];
      if (telaBase.empresasSelecionadas.length > 0) {
        telaBase.empresasSelecionadas.forEach((codigoEmpresa) => {
          const tipoEmpresa: ITipoDocumentoFinanceiroEmpresa = { codigo: 0, codigoTipoDocumentoFinanceiro: state.tipoDocumento.codigo, codigoEmpresa };
          state.tipoDocumento.empresas.push(tipoEmpresa);
        });
      }
      apresentarBarraProgresso();
      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
        retorno = await servicoTipoDocumentoFinanceiro.incluir(state.tipoDocumento);
      } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        retorno = await servicoTipoDocumentoFinanceiro.alterar(state.tipoDocumento);
      }
      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
          sincronizarRegistro(EPermissaoDados.Incluir, retorno.codigoRegistro);
        } else {
          sincronizarRegistro(EPermissaoDados.Alterar, props.operacao.codigoRegistro);
        }
        apresentarMensagemSucesso(retorno.mensagem);
        if (salvarNovo) {
          objetoInicial();
          const telaOperacao: ITelaOperacao = props.operacao;
          telaOperacao.codigoRegistro = 0;
          telaOperacao.tipoPermissaoDados = EPermissaoDados.Incluir;
          modalBase.computedOperacao = telaOperacao;
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      objetoInicial();
      if (modalBase.computedVisivel) {
        if (props.operacao.listaPermissoesDados.length > 0) {
          await preencherPermissoesDados(props.operacao.listaPermissoesDados);
        } else {
          await obterPermissoes(ETipoPermissao.Dados);
        }
        await preencherEmpresasComEstrategiaPermissaoDados(props.operacao.tipoPermissaoDados, true);
        await defineEmpresasSelecionadasComPermissao();
        telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);

        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
          modalBase.textoBotaoSalvar = 'Salvar alterações';
          modalBase.textoBotaoSalvarNovo = 'Salvar e novo';
          state.tipoDocumento = await servicoTipoDocumentoFinanceiro.obter(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
          telaBase.empresasSelecionadas = [];
          if (state.tipoDocumento.empresas.length > 0) {
            state.tipoDocumento.empresas.forEach((tipoEmpresa) => {
              telaBase.empresasSelecionadas.push(tipoEmpresa.codigoEmpresa);
            });
          }
        } else {
          modalBase.textoBotaoSalvar = 'Concluir cadastro';
          modalBase.textoBotaoSalvarNovo = 'Concluir e novo';
        }
      }
      telaBase.carregando = false;
    });

    return {
      telaBase,
      props,
      modalBase,
      EPermissaoDados,
      UtilitarioGeral,
      apresentarRetornoRequisicao,
      state,
      objetoInicial,
      salvar,
    };
  },
});
